<template>
  <section v-if="user.information">
      <ejs-schedule v-if="loaded" :key="redrawKey"      
        ref="ScheduleObj" 
        readOnly="true" 
        :height="heightPixels"
        :firstDayOfWeek='firstDay'
        :locale="user.information.locale" 
        :currentView="currentView"
        :actionComplete="onActionComplete"
        showHeaderBar="false"
        allowInline="false"
        allowAdding="false"
        allowEditing="false"
        enableMaxHeight="false"
        allowMultiRowSelection="false"
        showQuickInfo="false"
        @cellClick="onCellClick" 
        @cellDoubleClick="onCellDoubleClick"
        @popupOpen="popupOpen"
        @navigating="navigating"
        :eventSettings='eventSettings' @eventClick="onEventClick">

        <e-views>
          <e-view option="Day" :eventTemplate='agendaEventTemplate'></e-view>
          <e-view option="Week" :eventTemplate='agendaEventTemplate'></e-view>
          <e-view option="WorkWeek" :eventTemplate='agendaEventTemplate'></e-view>
          <e-view option="Agenda" :eventTemplate='agendaEventTemplate'></e-view>
      </e-views>

        </ejs-schedule>
  </section>
</template>
<script>
import { DataManager, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import localeText from '@/locales/calendar.json';
loadCldr(require("cldr-data/supplemental/numberingSystems.json"), require("cldr-data/main/sv/ca-gregorian.json"), require("cldr-data/main/sv/numbers.json"), require("cldr-data/main/sv/timeZoneNames.json"));
import { Day, Week, WorkWeek, Agenda } from '@syncfusion/ej2-vue-schedule';
import AgendaEventTemplate from "@/components/Calendar/AgendaEventTemplate";
class CustomAdaptor extends ODataV4Adaptor {
    processResponse() {
        let original = super.processResponse.apply(this, arguments);
        let arr = original.responseObject.items.map(item => (
          { 
            Id: item.id,
            Subject: item.subject,
            Location: item.location,
            Description: item.description,
            StartTime: item.startTime,
            EndTime: item.endTime,
            IsReadonly: item.isReadonly,
            Recurrence: item.recurrence,
            RoomId: item.roomId,
            MessageUuid: item.messageUuid
          }
        ));
        return  arr;
    }
}
export default {
  props: ["currentView","redrawView"],
  provide: {
    schedule: [Day, Week, WorkWeek, Agenda]
  },
  components: {
  },
  data() {
    return {
      firstDay: 1,
      redrawIndex: 0,
      selectedDate: null,
      readOnly: true,
      items: [],
      limit: 500,
      totalItems: 0,
      localeText,
      loaded: false,
      locale: 'sv',
      agendaEventTemplate: function () {
          return { template: AgendaEventTemplate }
      },
      eventSettings: {
        dataSource: []
      }
    };
  },
methods: {
    onCellClick(args){
      args.cancel = true;
    },
    onCellDoubleClick(args){
      args.cancel = true;
    },
    popupOpen(args){
      args.cancel = true;
    },
    onActionComplete(){
      if(this.currentView == "WorkWeek" || this.currentView == "Week")
      {
        /*setTimeout(() => {
          var element = document.getElementsByClassName('e-current-time')[0];
          element.scrollIntoView({
            behavior: "instant"
          });
        }, "1000");*/
      }
    },
    navigating(){
      this.$emit('changedView', this.currentView);
    },
    onEventClick: function (obj) {
      this.$emit('clickedEvent', obj.event);
    },
    init() {      
      if(this.teams.context == null)
      {
        this.eventSettings.dataSource = new DataManager(
          {
              url: this.user.hostname + "/meetings/list",
              adaptor: new CustomAdaptor(),
              crossDomain: true,
              headers:[{ 'Authorization': "Bearer " + this.$cookies.get("token") }]
          }
        );
      }else{
        this.eventSettings.dataSource = new DataManager(
          {
              url: this.user.hostname + "/meetings/list",
              adaptor: new CustomAdaptor(),
              crossDomain: true,
              headers:[{ 'Authorization': "Bearer " + this.user.token }]
          }
        );
      }
      setCulture(this.user.information.locale);
      L10n.load(this.localeText);
      this.loaded = true;
    },
  },
  computed: {
    heightPixels(){
      let pixels = (window.innerHeight - 200);
      return pixels + "px";
    },
    redrawKey(){
      return "events_" + this.redrawIndex;
    }
  },
  mounted: function() {
    this.init();
  },
};
</script>
<style></style>
