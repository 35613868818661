var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { key: "sent_" + _vm.redrawIndex, staticClass: "mt-2" },
    [
      _vm.searchText != ""
        ? _c(
            "div",
            [
              _c("b-table", {
                attrs: {
                  small: "",
                  bordered: "",
                  items: _vm.items,
                  hover: "",
                  fields: _vm.fields,
                  busy: _vm.loading,
                },
                on: { "row-clicked": _vm.clickedRow },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(message.created_date)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.FormatDateTimeRelative(
                                data.item.message.created_date
                              )
                            )
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(message.open_from_date)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.FormatDateTime(
                                data.item.message.open_from_date
                              )
                            )
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(message.open_to_date)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.FormatDateTime(data.item.message.open_to_date)
                            )
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(message.size)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.bytesToSize(data.item.message.size)) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(message.message_type)",
                      fn: function (data) {
                        return [
                          _c("b-img", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: {
                              height: "20",
                              width: "20",
                              title: _vm.$t(
                                "MESSAGES.TYPE." +
                                  data.item.message.message_type
                              ),
                              alt: _vm.$t(
                                "MESSAGES.TYPE." +
                                  data.item.message.message_type
                              ),
                              src: _vm.getLogo(data.item.message.message_type),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "cell(message.has_attachment)",
                      fn: function (data) {
                        return [
                          data.item.message.has_attachment
                            ? _c("span", [
                                _c("i", { staticClass: "fal fa-paperclip" }),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(message.id)",
                      fn: function (data) {
                        return [
                          data.item.message.has_attachment
                            ? _c("span", [
                                _c("i", { staticClass: "fal fa-paperclip" }),
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n        " + _vm._s(data.item.message.subject)
                          ),
                          _c("br"),
                          _c("small", [
                            _vm._v(
                              _vm._s(
                                _vm.FormatDateTimeRelative(
                                  data.item.message.created_date
                                )
                              )
                            ),
                          ]),
                          _c("br"),
                          _c("SentRecipients", {
                            attrs: {
                              message_uuid: data.item.message.message_uuid,
                              external: data.item.external,
                              participants: data.item.participants,
                              external_system_participant:
                                data.item.external_system_participant,
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "cell(message.loa_level)",
                      fn: function (data) {
                        return [
                          data.item.message.loa_level != 1
                            ? _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "fal fa-info-square",
                                attrs: {
                                  title: _vm.$t(
                                    "LOA.INFO." + data.item.message.loa_level
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "LOA.LEVEL." + data.item.message.loa_level
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(message.custom_identifier)",
                      fn: function (data) {
                        return [
                          _c("SentRecipients", {
                            attrs: {
                              message_uuid: data.item.message_uuid,
                              external: data.item.external,
                              participants: data.item.participants,
                              external_system_participant:
                                data.item.external_system_participant,
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "table-busy",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center my-2" },
                            [_c("b-spinner", { staticClass: "align-middle" })],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3785123158
                ),
              }),
              _c("b-pagination", {
                staticClass: "mt-2",
                attrs: {
                  "total-rows": _vm.totalItems,
                  "per-page": _vm.limit,
                  pills: "",
                },
                on: { input: _vm.getSent },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.searchText == ""
        ? _c("MeetingsCalendar", {
            key: "MeetingsCalendar_" + _vm.redrawIndex,
            attrs: {
              currentView: _vm.currentView,
              redrawView: _vm.listMeetingKey,
            },
            on: {
              clickedEvent: _vm.clickedEvent,
              changedView: _vm.changedView,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }