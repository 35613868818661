<template>
  <section class="mt-2" :key="'sent_' + redrawIndex">
    <div v-if="searchText != ''">
      <b-table
        small
        bordered
        :items="items"
        hover
        :fields="fields"
        :busy="loading"
        @row-clicked="clickedRow"
      >
        <template v-slot:cell(message.created_date)="data">{{
          FormatDateTimeRelative(data.item.message.created_date)
        }}</template>

        <template v-slot:cell(message.open_from_date)="data">{{
          FormatDateTime(data.item.message.open_from_date)
        }}</template>

        <template v-slot:cell(message.open_to_date)="data">{{
          FormatDateTime(data.item.message.open_to_date)
        }}</template>

        <template v-slot:cell(message.size)="data">
          {{ bytesToSize(data.item.message.size) }}
        </template>

        <template v-slot:cell(message.message_type)="data">
          <b-img
            height="20"
            width="20"
            v-b-tooltip.hover
            :title="$t('MESSAGES.TYPE.' + data.item.message.message_type)"
            :alt="$t('MESSAGES.TYPE.' + data.item.message.message_type)"
            :src="getLogo(data.item.message.message_type)"
          ></b-img>
        </template>

        <template v-slot:cell(message.has_attachment)="data">
          <span v-if="data.item.message.has_attachment"
            ><i class="fal fa-paperclip"
          /></span>
        </template>

        <template v-slot:cell(message.id)="data">
          <span v-if="data.item.message.has_attachment"
            ><i class="fal fa-paperclip"
          /></span>
          {{ data.item.message.subject }}<br />
          <small>{{
            FormatDateTimeRelative(data.item.message.created_date)
          }}</small>
          <br /><SentRecipients
            :message_uuid="data.item.message.message_uuid"
            :external="data.item.external"
            :participants="data.item.participants"
            :external_system_participant="data.item.external_system_participant"
          ></SentRecipients>
        </template>

        <template v-slot:cell(message.loa_level)="data">
          <i
            v-if="data.item.message.loa_level != 1"
            class="fal fa-info-square"
            v-b-tooltip.hover
            :title="$t('LOA.INFO.' + data.item.message.loa_level)"
          ></i>
          {{ $t("LOA.LEVEL." + data.item.message.loa_level) }}
        </template>

        <template v-slot:cell(message.custom_identifier)="data">
          <SentRecipients
            :message_uuid="data.item.message_uuid"
            :external="data.item.external"
            :participants="data.item.participants"
            :external_system_participant="data.item.external_system_participant"
          ></SentRecipients>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>

      <b-pagination
        class="mt-2"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="limit"
        @input="getSent"
        pills
      >
      </b-pagination>
    </div>
    
    <MeetingsCalendar
      v-if="searchText == ''"
      :key="'MeetingsCalendar_' + redrawIndex"
      @clickedEvent="clickedEvent"
      @changedView="changedView"
      :currentView="currentView"
      :redrawView="listMeetingKey"
    ></MeetingsCalendar>
  </section>
</template>
<script>
import SentRecipients from "@/components/View/SentRecipients";
import MeetingsCalendar from "@/components/Calendar/MeetingsCalendar";
export default {
  props: ["refresh","searchText", "limit", "update_message_uuid"],
  components: {
    SentRecipients,
    MeetingsCalendar,
  },
  data() {
    return {
      selectedIndex: 0,
      redrawIndex: 0,
      loading: false,
      message_uuid: "",
      currentPage: 1,
      items: [],
      totalItems: 0,
      state: 1,
      currentView: "WorkWeek",
      options: [
        { text: this.$t("ACTIVE"), value: 1 },
        { text: this.$t("INACTIVE"), value: 0 },
      ],
      fields: [
        {
          key: "message.id",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          key: "message.has_attachment",
          label: "",
          class: "text-break w-icon clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "message.message_type",
          label: this.$t("TYPE"),
          class: "text-break w-icon clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "message.open_from_date",
          label: this.$t("FROM"),
          class: "text-break w-date clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "message.open_to_date",
          label: this.$t("TO"),
          class: "text-break w-date clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "message.subject",
          label: this.$t("SUBJECT"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "message.custom_identifier",
          label: this.$t("RECIPIENTS"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "message.loa_level",
          label: "",
          class: "text-break w-150 clickable hidden-mobile",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    changeList() {
      this.currentPage = 1;
      this.getSent();
    },
    getLogo(item) {
      return "/img/message_type/" + item + ".png";
    },
    clickedRow(data, index) {
      this.selectedIndex = index;
      this.$emit("viewingMessage", data.message.message_uuid);
    },
    getSent: function () {
      /*let self = this;
      this.loading = true;
      this.$http
        .post(this.user.hostname + "/inbox/sent", {
          page: this.currentPage,
          limit: parseInt(this.limit),
          state: this.state,
          search_text: this.searchText,
          type: "meeting",
        })
        .then((response) => {
          self.$emit("changeUpdateMessageUuid", "");
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
          self.redrawIndex++;
        })
        .catch(() => {
          self.loading = false;
        });*/
        console.log("GetSent");
    },
    UpdateItem: function (message_uuid) {
      if (message_uuid != "") {
        let self = this;
        this.$http
          .post(this.user.hostname + "/inbox/message-sent-update", {
            functionbox_uuid: "",
            message_uuid: message_uuid,
          })
          .then((response) => {
            if (
              response.data.message.removed == 1 ||
              response.data.message.removed == 1
            ) {
              self.items.splice(self.selectedIndex, 1);
            } else {
              self.items[self.selectedIndex] = response.data;
            }
            self.redrawIndex = self.redrawIndex + 1;
          })
          .catch(() => {});
      }
    },
    clickedEvent(item) {
      item.EndTime = item.EndTime.toISOString();     
      item.StartTime = item.StartTime.toISOString();        
      this.$emit("viewingMessage", item);
    },
    changedView(view) {
      this.currentView = view;
    },
  },
  computed: {
    listMeetingKey() {
      return "listMeetingKey_" + this.redrawIndex;
    },
  },
  watch: {
    searchText: function () {
      if (this.searchText.length == 0) {
        this.getSent();
      }
      if (this.searchText.length >= 2) {
        this.getSent();
      }
    },
    refresh(){
      this.redrawIndex++;
    },
    limit: function () {
      this.getSent();
    },
  },
  mounted: function () {
    this.getSent();
  },
};
</script>
<style>
</style>
